.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reviewsContainer {
  display: flex;
  margin: 0 -12px;
  padding: 12px 0;
  flex-wrap: wrap;
}
