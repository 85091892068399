.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 40%;
  min-height: 110px;
  margin: 12px;
  padding: 20px;
  border-radius: 20px;
  background-color: #f7f7f7;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.card.faded {
  opacity: 0;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.title {
  display: -webkit-box;
  font-size: 1.1em;
  font-weight: bold;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.stars {
  margin-left: 8px;
  color: #F58F2E;
}

.text {
  display: -webkit-box;
  margin: 8px 0;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.metadataContainer {
  text-align: right;
}

.metadata {
  font-style: italic;
  color: gray;
}

@media screen and (max-width: 655px) {
  .card {
    min-height: 96px;
  }

  .text {
    -webkit-line-clamp: 2;
  }
}
