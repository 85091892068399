.homeButton {
  display: inline-block;
  align-items: center;
  height: 40px;
  margin: 32px 0;
  padding: 0 15px;
  border-radius: 8px;
  color: white;
  background-color: #A37447;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
}
