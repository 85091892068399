.footer {
  padding: 20px 0 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.links {
  margin-top: 8px;
}

.separator {
  margin: 0 8px;
  color: gray;
}

.copyrightNotice {
  margin-top: 8px;
  color: gray;
}
