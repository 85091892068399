.hero {
  position: relative;
  max-width: 100%;
  height: 512px;
  margin: 0 calc((100vw - min(100vw - 64px, 896px)) / -2);
  padding: 0 calc((100vw - min(100vw - 64px, 896px)) / 2);
  display: flex;
  background-color: #FCF0E5;
  overflow: hidden;
}

.leftSide {
  width: calc(896px - 768px / 2 - 256px * 0.7 - 32px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 96px;
  height: 96px;
}

.title {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 4em;
  font-weight: normal;
  letter-spacing: -0.025em;
}

.subtitle {
  margin: 0;
  color: gray;
}

.appStoreBanner {
  width: 120px;
  height: 40px;
  margin-top: 20px;
}

.iPhoneScreenshot {
  width: 256px;
  height: calc(904px / 456 * 256);
  position: absolute;
  top: 120px;
  right: calc((100vw - min(100vw - 64px, 896px)) / 2 + 768px / 2 - (936px - min(936px, 100vw)) - 256px * 0.3);
  z-index: 1;
}

.iPadScreenshot {
  width: 768px;
  height: calc(960px / 1320 * 768);
  position: absolute;
  top: 50px;
  right: calc((100vw - min(100vw - 64px, 896px)) / 2 - 768px / 2 - (936px - min(936px, 100vw)));
}

@media screen and (max-width: 767px) {
  .hero {
    height: auto;
    padding-bottom: 32px;
    flex-direction: column;
  }
  .leftSide {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: flex-start;
  }

  .iPhoneScreenshot {
    top: auto;
    right: auto;
    bottom: 32px;
  }

  .iPadScreenshot {
    position: relative;
    top: auto;
    left: calc(256px * 0.7);
    bottom: 0;
  }
}
