.container {
  flex: 1 0 40%;
  margin: 20px;
}

.icon {
  width: 96px;
  height: 96px;
}

.title {
  margin-bottom: 12px;
  font-family: 'Noto Sans JP', sans-serif;
  color: #A37447;
}

.text {
  margin-top: 12px;
  line-height: 1.4em;
}
