.heading {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 2.5em;
  color: #A37447;
}

@media screen and (max-width: 767px) {
  .heading {
    font-size: 2em;
  }
}
