.section {
  display: flex;
  margin: 0;
}

.section:nth-child(even) {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text {
  font-size: 1.2em;
  line-height: 1.5em;
}

.screenshot {
  width: 320px;
  height: calc(904px / 456 * 320);
}

.iPadScreenshot {
  width: 320px;
  height: calc(1320px / 960 * 320);
}

.screenshotContainer {
  width: 320px;
  height: calc(904px / 456 * 320);
  margin: 0 40px 0 0;
}

.iPadScreenshotContainer {
  width: 320px;
  height: calc(1320px / 960 * 320);
  margin: 0 40px 0 0;
}

.section:nth-child(even) .screenshotContainer {
  margin: 0 0 0 40px;
}

.gradient {
  display: none;
}

@media screen and (max-width: 767px) {
  .section {
    flex-direction: column-reverse;
    align-items: center;
  }

  .section:nth-child(even) {
    flex-direction: column-reverse;
  }

  .screenshotContainer {
    position: relative;
    height: calc(904px / 456 * 320 * 0.75);
    margin: 0 0 64px;
    overflow-y: hidden;
  }

  .iPadScreenshotContainer {
    position: relative;
    height: calc(1320px / 960 * 320 * 0.75);
    margin: 0 0 64px;
    overflow-y: hidden;
  }

  .section:nth-child(even) .screenshotContainer {
    height: calc(904px / 456 * 320 * 0.75);
    margin: 0 0 64px;
  }

  .section:nth-child(even) .iPadScreenshotContainer {
    height: calc(1320px / 960 * 320 * 0.75);
    margin: 0 0 64px;
  }

  .gradient {
    display: block;
    position: absolute;
    bottom: 0;
    width: 320px;
    height: 40px;
    background: linear-gradient(180deg, #FFFFFF00, #FFFFFFFF);
  }
}
