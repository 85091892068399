.section {
  margin: 64px 0;
}

.introduction {
  min-width: calc(320px - 64px);
  width: 75%;
  margin: auto;
  font-size: 1.2em;
  line-height: 1.35em;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .introduction {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 511px) {
  .introduction {
    width: 100%;
  }
}
